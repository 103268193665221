'use client'

import { X } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { ICouponCode } from '@repo/utilities'

import { getLatestActiveCoupon } from '@/_actions/coupon'
import { Button } from '@/_shadcn/components/ui/button'

import { Container } from '../Container'

export const LimitedTimeBanner = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [isVisible, setIsVisible] = useState(true)
  const [coupon, setCoupon] = useState<ICouponCode | null>(null)

  const updateTimeLeft = useCallback((expiresAt: Date | string) => {
    const update = () => {
      const now = new Date().getTime()
      const expiry = new Date(expiresAt).getTime()
      const totalSeconds = Math.floor((expiry - now) / 1000)

      if (totalSeconds <= 0) {
        setIsVisible(false)
        return
      }

      const days = Math.floor(totalSeconds / (24 * 3600))
      const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = totalSeconds % 60

      setTimeLeft({ days, hours, minutes, seconds })
    }

    update()
    const timer = setInterval(update, 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    const fetchCoupon = async () => {
      const latestCoupon = await getLatestActiveCoupon()

      if (latestCoupon) {
        setCoupon(latestCoupon)
        updateTimeLeft(latestCoupon.expiresAt)
      } else {
        setIsVisible(false)
      }
    }
    fetchCoupon()
  }, [updateTimeLeft])

  const copyCodeToClipboard = async () => {
    if (!coupon) return
    try {
      await navigator.clipboard.writeText(coupon.code)
      toast.success('Copied to clipboard!')
    } catch (err) {
      toast.error('Failed to copy!')
    }
  }

  if (!isVisible || !coupon) return null

  return (
    <div className='sticky top-0 left-0 right-0 z-50 bg-red-500 text-white animate-in fade-in slide-in-from-top-2'>
      <Container>
        <div className='flex flex-col gap-2 items-center justify-between py-3 sm:flex-row'>
          <div className='flex flex-col items-center space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row'>
            <div className='flex flex-col'>
              <span className='font-semibold text-center sm:text-left'>
                {coupon.title}
              </span>
              <span className='text-sm text-white text-center sm:text-left'>
                Get {coupon.discountPercentage}% off your first year
              </span>
            </div>
            <div className='flex items-center'>
              {[
                { value: timeLeft.days, label: 'D' },
                { value: timeLeft.hours, label: 'H' },
                { value: timeLeft.minutes, label: 'M' },
                { value: timeLeft.seconds, label: 'S' },
              ].map((item) => (
                <div
                  key={item.label}
                  className='flex flex-col items-center w-12'
                >
                  <span className='text-2xl font-bold tabular-nums text-white'>
                    {String(item.value).padStart(2, '0')}
                  </span>
                  <span className='text-xs text-white/80'>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col items-center sm:items-end space-y-2'>
            <Button
              variant='secondary'
              size='sm'
              className='whitespace-nowrap'
              onClick={copyCodeToClipboard}
            >
              Use code:{' '}
              <span className='font-semibold pt-[1px]'>{coupon.code}</span>
            </Button>
            <span className='text-xs text-white/80 italic'>
              *Use code at checkout on any plan
            </span>
          </div>
          <Button
            variant='ghost'
            size='icon'
            className='absolute top-1 right-1 text-primary-foreground hover:text-red-500'
            onClick={() => setIsVisible(false)}
          >
            <X className='h-4 w-4' />
            <span className='sr-only'>Close banner</span>
          </Button>
        </div>
      </Container>
    </div>
  )
}
